import LazyLoad from "vanilla-lazyload";
import { lazyConfig } from "../lazy";
var IMG_SELECTOR = 'picture.lazy:not(.lazy--loaded) img:not(.loading)';
export default function LazyImages(_a) {
    var swiper = _a.swiper, on = _a.on, off = _a.off;
    on('init', function (swiper) {
        // задержка нужна, чтобы не происходило отмены загрузки картинок
        setTimeout(function () { return check(swiper); }, 100);
    });
    on('slideChangeTransitionStart', check);
    on('slideChangeTransitionEnd', check);
    function check(swiper) {
        var slidesPerView = swiper.params.slidesPerView === 'auto' ? 1 : swiper.params.slidesPerView;
        var activeIndex = swiper.activeIndex;
        var totalSlides = getTotalSlidesNumber();
        var updated = preloadImages(activeIndex + slidesPerView, activeIndex + slidesPerView + slidesPerView);
        if (!swiper.loopedSlides) {
            return;
        }
        var startIndex = (swiper.realIndex < slidesPerView ? totalSlides + swiper.realIndex - slidesPerView : swiper.realIndex - slidesPerView);
        for (var i = startIndex; i < startIndex + slidesPerView; i++) {
            swiper.$wrapperEl.find(".".concat(swiper.params.slideClass, "[data-swiper-slide-index=\"").concat(i, "\"] ").concat(IMG_SELECTOR)).each(function (image) {
                if (preloadImage(image)) {
                    updated = true;
                }
            });
        }
        if (updated) {
            return;
        }
        if (swiper.$wrapperEl.find(IMG_SELECTOR).length) {
            return;
        }
        off('init', check);
        off('slideChangeTransitionStart', check);
        off('slideChangeTransitionEnd', check);
    }
    function preloadImages(start, end) {
        var updated = false;
        for (var i = start; i < end; i++) {
            if (!swiper.slides[i]) {
                break;
            }
            if (preloadImage(swiper.slides[i].querySelector(IMG_SELECTOR))) {
                updated = true;
            }
        }
        return updated;
    }
    function preloadImage(image) {
        if (!image) {
            return false;
        }
        LazyLoad.load(image, lazyConfig);
        return true;
    }
    function getTotalSlidesNumber() {
        if (!swiper.loopedSlides) {
            return swiper.slides.length;
        }
        return swiper.$wrapperEl.find(".".concat(swiper.params.slideClass, ":not(.").concat(swiper.params.slideDuplicateClass, ")")).length;
    }
}
