var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { Autoplay, Navigation, Pagination, Swiper, Thumbs } from "swiper";
import AutoplayPauseOnFocus from "./swiper/AutoplayPauseOnFocus";
import FixTabIndex from "./swiper/FixTabIndex";
import LazyImages from "./swiper/LazyImages";
var swiperDefaults = {
    speed: 500,
};
export default function initSliders() {
    makeBannersSlider();
    makeSwiper('.brands-slider', [Autoplay, Pagination, Navigation], { loop: true });
    makeSwiper('.reviews-slider', [Autoplay, Navigation, LazyImages], { loop: true });
    makeProdCarouselSwiper();
}
export function makeProductThumbsSlider() {
    return makeSwiper('.prod-pager', [Navigation, LazyImages], {
        slidesPerView: 3,
        shortSwipes: false,
        on: {
            init: function (swiper) {
                if (swiper.slides.length <= 3) {
                    swiper.$el.addClass('swiper-centered');
                }
            },
        },
    });
}
export function makeProductSlider(thumbsSwiper) {
    return makeSwiper('.prod-slider', [Thumbs, LazyImages], {
        thumbs: {
            swiper: thumbsSwiper,
            autoScrollOffset: 0,
        },
    });
}
export function makeBannersSlider() {
    var interleaveOffset = 0.45;
    return makeSwiper('.banners-slider', [Autoplay, Pagination, Navigation, LazyImages], {
        loop: true,
        slidesPerView: 'auto',
        speed: 1000,
        pagination: {
            el: document.querySelector('.banners-slider+.swiper-pagination'),
        },
        on: {
            progress: function (swiper) {
                for (var i = 0; i < swiper.slides.length; i++) {
                    var innerOffset = swiper.width * interleaveOffset;
                    // @ts-ignore
                    var innerTranslate = swiper.slides[i].progress * innerOffset;
                    swiper.slides[i].firstElementChild.style.transform = "translateX(".concat(innerTranslate, "px)");
                }
            },
            touchStart: function (swiper) {
                for (var i = 0; i < swiper.slides.length; i++) {
                    swiper.slides[i].style.transition = "";
                }
            },
            setTransition: function (swiper, speed) {
                for (var i = 0; i < swiper.slides.length; i++) {
                    swiper.slides[i].style.transition = "".concat(speed, "ms");
                    swiper.slides[i].firstElementChild.style.transition = "".concat(speed, "ms");
                }
            }
        }
    });
}
export function makeTagsSlider() {
    return makeSwiper('.tags-slider', [Pagination], { loop: false });
}
export function makeSimilarProductsCarousel() {
    return makeSwiper('.same-prods__carousel', [Navigation, LazyImages], {
        slidesPerGroup: 1,
        slidesPerView: 1,
        breakpoints: {
            350: {
                slidesPerGroup: 2,
                slidesPerView: 2,
            },
            720: {
                slidesPerGroup: 3,
                slidesPerView: 3,
            },
            900: {
                slidesPerGroup: 4,
                slidesPerView: 4,
            },
        },
        breakpointsBase: 'container',
    });
}
export function makeProdCarouselSwiper() {
    makeSwiper('.prod-carousel', [Navigation, LazyImages], {
        slidesPerGroup: 1,
        slidesPerView: 1,
        breakpoints: {
            350: {
                slidesPerGroup: 2,
                slidesPerView: 2,
            },
            720: {
                slidesPerGroup: 3,
                slidesPerView: 3,
            },
            940: {
                slidesPerGroup: 4,
                slidesPerView: 4,
            },
            1140: {
                slidesPerGroup: 5,
                slidesPerView: 5,
            }
        },
        breakpointsBase: 'container',
    });
}
function makeSwiper(container, modules, options) {
    var e_1, _a;
    if (modules === void 0) { modules = []; }
    if (options === void 0) { options = {}; }
    var builder = getOptions(options);
    try {
        for (var modules_1 = __values(modules), modules_1_1 = modules_1.next(); !modules_1_1.done; modules_1_1 = modules_1.next()) {
            var module = modules_1_1.value;
            if (module === Autoplay) {
                builder.addAutoplay();
            }
            else if (module === Pagination) {
                builder.addPagination();
            }
            else if (module === Navigation) {
                builder.addNavigation();
            }
            else {
                builder.addModule(module);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (modules_1_1 && !modules_1_1.done && (_a = modules_1.return)) _a.call(modules_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return new Swiper(container, builder.getOptions());
}
function getOptions(options) {
    var _this = this;
    if (options) {
        options = __assign(__assign({}, swiperDefaults), options);
    }
    else {
        options = __assign({}, swiperDefaults);
    }
    if (!options.modules) {
        options.modules = [];
    }
    var addModule = function (module) {
        options.modules.push(module);
        return _this;
    };
    var addAutoplay = function () {
        addModule(Autoplay);
        addModule(AutoplayPauseOnFocus);
        options = __assign(__assign({}, options), { autoplay: {
                delay: 5000,
                pauseOnMouseEnter: false,
                disableOnInteraction: true,
            } });
        return _this;
    };
    var addNavigation = function () {
        addModule(Navigation);
        options.navigation = {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        };
        return _this;
    };
    var addPagination = function () {
        addModule(Pagination);
        options.pagination = __assign({ el: '.swiper-pagination', clickable: true, renderBullet: function (index, className) { return "<button class=\"".concat(className, "\"></button>"); } }, options.pagination);
        return _this;
    };
    addModule(FixTabIndex);
    var getOptions = function () { return options; };
    return {
        addModule: addModule,
        addAutoplay: addAutoplay,
        addNavigation: addNavigation,
        addPagination: addPagination,
        getOptions: getOptions,
    };
}
