import $ from "../../vendor/dom7";
export default function FixTabIndex(_a) {
    var swiper = _a.swiper, on = _a.on;
    on('beforeInit', function () {
        swiper.params.watchSlidesProgress = true;
    });
    on('init', fixTabIndex);
    on('slideChangeTransitionStart', fixTabIndex);
    function fixTabIndex(swiper) {
        swiper.slides.forEach(function (slide) {
            if (slide.matches('.swiper-slide-visible')) {
                $(slide).find('[tabindex="-1"]')
                    .attr('tabindex', '');
            }
            else {
                $(slide).find('a, button, input, select, textarea, details')
                    .filter(function (element) { return element.tabIndex !== -1; })
                    .attr('tabindex', "-1");
            }
        });
    }
}
