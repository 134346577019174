export default function AutoplayPauseOnFocus(_a) {
    var on = _a.on;
    var focused = false;
    var hovered = false;
    on('afterInit', function (swiper) {
        if (!swiper.params.autoplay) {
            return;
        }
        swiper.$el
            .on('mouseenter', function () {
            hovered = true;
            fixAutoplay(swiper);
        })
            .on('mouseleave', function () {
            hovered = false;
            fixAutoplay(swiper);
        });
        swiper.$el.find('a, button')
            .on('focus', function () {
            focused = true;
            fixAutoplay(swiper);
        })
            .on('blur', function () {
            focused = false;
            fixAutoplay(swiper);
        });
    });
    function fixAutoplay(swiper) {
        if (!swiper.autoplay.running) {
            return;
        }
        if ((hovered || focused) && !swiper.autoplay.paused) {
            swiper.autoplay.pause();
        }
        else if (!(hovered || focused) && swiper.autoplay.paused) {
            swiper.autoplay.paused = false;
            swiper.autoplay.run();
        }
    }
}
